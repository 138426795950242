"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import search from "@/public/images/search.svg";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import GENERAL, { SelectSchoolYears } from "@/redux/GENERAL";
import Link from "next/link";
import MyLink from "@/components/shared/myLink/MyLink";

function SearchSection({ home }: any) {
  const dispatch = useAppDispatch();
  const schoolYear = useAppSelector(SelectSchoolYears);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSchoolYears = async () => {
      await dispatch(GENERAL.thunks.doGetSchoolYears({}));
      setLoading(false);
    };
    fetchSchoolYears();
  }, [dispatch]);

  return (
    <div className="container my-10">
      <div className="flex flex-col md:flex-row justify-between gap-5 w-full bg-[#5F4D46] rounded-[20px] p-3">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-2 items-center justify-center w-full">
          {loading ? (
            <SkeletonTheme baseColor="#695750" highlightColor="#6b6e72">
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <Skeleton key={index} className="rounded-[5px] w-full h-10" />
                ))}
            </SkeletonTheme>
          ) : (
            schoolYear.map((year: any, i: number) => (
              <MyLink
                href={`/courses?schoolyears_ids=${year?.id}`}
                className="bg-[#695750] text-center text-white rounded-[5px] text-xs py-3 px-2"
                key={i}
              // onClick={(e) => {
              //   schoolYearSelected.includes(year.id)
              //     ? setschoolYearSelected((prev: any) =>
              //         prev.filter((item: any) => item !== year.id)
              //       )
              //     : setschoolYearSelected((prev: any) => [...prev, year.id]);
              // }}
              // className={` ${
              //   schoolYearSelected.includes(year.id)
              //     ? " bg-primaryColor text-[#ffffff]"
              //     : "bg-[#695750] text-white"
              // }   rounded-[5px] text-14 w-[145px] md:w-fit max-w-[250px] py-3 px-5`}
              >
                {year?.name}
              </MyLink>
            ))
          )}
        </div>
        {/* <MyLink
          className="self-center md:self-auto"
          href={`/courses?schoolyears_ids=${schoolYearSelected.join("_")}`}
        >
          <Image src={search} alt="search" />
        </MyLink> */}
      </div>
    </div>
  );
}

export default SearchSection;
