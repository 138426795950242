import React from "react";
import cap from "@/public/images/cap.svg";
import Image from "next/image";
import Link from "next/link";
import defaultImage from "@/public/images/default_image.jpg";
import MyLink from "../myLink/MyLink";

const index = ({
  id,
  image,
  subject,
  teacherName,
  description,
  schoolYears,
  full = true,
}: {
  id: any;
  image: any;
  subject: string;
  teacherName: string;
  description?: string;
  schoolYears?: any[];
  full?: boolean;
}) => {
  return (
    <MyLink href={`instructors/${id}`}>
      <div
        className=" p-[20px] max-w-full bg-[#5F4D46] rounded-[20px] flex flex-col gap-3"
        data-aos="fade-up"
      >
        <div className=" w-full h-[280px] relative  rounded-[20px]">
          <Image
            src={image || defaultImage}
            alt="teacherImage"
            sizes="100vw"
            fill
            style={{ objectFit: "cover" }}
            className=" rounded-[20px] object-top"
          />
        </div>

        <div className=" flex items-center gap-3 mt-2">
          <Image src={cap} alt="cap" />
          <p className=" text-16 text-white">{subject}</p>
        </div>
        <p className=" text-[20px] text-white font-[600] ">{teacherName}</p>
        {full && (
          <>
            <p className=" text-16 text-[#BEC4E0] line-clamp-2">
              {description}
            </p>
            <div className="flex gap-2 flex-wrap">
              {schoolYears &&
                schoolYears?.map((item: any) => (
                  <p
                    key={item.id}
                    className=" bg-[#695750] rounded-[5px] text-xs text-textColor w-fit py-1 px-2"
                  >
                    {item.name}
                  </p>
                ))}
            </div>
          </>
        )}
      </div>
    </MyLink>
  );
};

export default index;
